(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

var header = document.querySelector('.header');
window.addEventListener('scroll', function () {
  if (window.pageYOffset !== 0) {
    header.classList.add('header--fixed');
  } else {
    header.classList.remove('header--fixed');
  }
});
/* Slider */

var heroGallery = tns({
  container: '.hero__slider',
  mode: 'gallery',
  slideBy: 'page',
  controlsText: ["", ""],
  nav: false,
  autoplay: true,
  controls: false
});
/* Video */

var videoButtons = document.querySelectorAll('.video-button');
var videoPlayerPopup = document.querySelector('.video-player');
var videoPlayer = document.getElementById('video');
var videoPlayerClose = document.querySelector('.video-player__close');
videoButtons.forEach(function (button) {
  button.addEventListener('click', function () {
    videoPlayer.children[0].src = button.dataset.source;
    videoPlayer.load();
    videoPlayerPopup.classList.add('video-player--active');
  });
});
videoPlayerClose.addEventListener('click', function () {
  videoPlayerPopup.classList.remove('video-player--active');
});

},{}]},{},[1]);
